import './App.css';
import jsondata from './data.json';
import React, { Fragment, useState } from 'react';
import 'bulma/css/bulma.min.css';
import Obfuscate from 'react-obfuscate';

function ComboTable(props) {
  let dict = new Map();
  dict.set(1, props.p1);
  dict.set(2, props.p2);
  dict.set(3, props.p3);
  dict.set(4, props.p4);
  dict.set(5, props.p5);
  dict.set(6, props.p6);

  const [staredDouble, setstaredDouble] = useState([]);


  function callbackstar(row, useStar) {
    if (useStar) {
      setstaredDouble([...staredDouble, row]);
      props.pass_stars([...staredDouble, row]);
      console.log("callbackstar", [...staredDouble, row]);
    }
    else {
      // console.log("else works");
      let tmp = [...staredDouble];
      console.log("tmp", tmp)
      let tmp2 = tmp.map((e) => e.every((el, ix) => el === row[ix]));
      console.log("mask", tmp2);
      let tmp3 = tmp.filter((el, ix) => !(tmp2[ix]));
      setstaredDouble([...tmp3]);
      props.pass_stars([...tmp3]);
      console.log("callbackstar", [...tmp3]);
    }


    //  console.log(staredDouble);
  }

  const DisplayData = jsondata.map(i => {
    const dp1 = dict.get(i.double_1.player_1);
    const dp2 = dict.get(i.double_1.player_2);
    const dp3 = dict.get(i.double_2.player_1);
    const dp4 = dict.get(i.double_2.player_2);
    const dp5 = dict.get(i.double_3.player_1);
    const dp6 = dict.get(i.double_3.player_2);

    const double_1 = dp1 + dp2;
    const double_2 = dp3 + dp4;
    const double_3 = dp5 + dp6;
    const lineup = [double_1, double_2, double_3];
    //console.log("lineup", lineup);
    const row = [dp1, dp2, dp3, dp4, dp5, dp6];
    const mask = staredDouble.map((e) => e.every((el, ix) => el === row[ix]));
    const is_checked = mask.includes(true);



    if (
      props.p_filter.every(v => lineup.includes(v))
    ) {
      return (
        <tr>
          <td>{dp1} + {dp2}</td>
          <td>{dp3} + {dp4}</td>
          <td>{dp5} + {dp6}</td>
          <td><StarCheckbox p1={dp1} p2={dp2} p3={dp3} p4={dp4} p5={dp5} p6={dp6} checked={is_checked} callback={callbackstar} /></td>
        </tr>
      );
    }
  })

  return (
    <div>
      <table class="table is-fullwidth">
        <th colSpan="1"><abbr title="Doppel 1">Doppel 1</abbr></th>
        <th colSpan="1"><abbr title="Doppel 2">Doppel 2</abbr></th>
        <th colSpan="1"><abbr title="Doppel 3">Doppel 3</abbr></th>
        <th colSpan="1"><abbr title="Favorit">⭐</abbr></th>
        <tbody>
          {DisplayData}
        </tbody>
      </table>
    </div>
  );
}

function Player() {
  const [player1, setplayer1] = useState("1");
  const [player2, setplayer2] = useState("2");
  const [player3, setplayer3] = useState("3");
  const [player4, setplayer4] = useState("4");
  const [player5, setplayer5] = useState("5");
  const [player6, setplayer6] = useState("6");

  const [fixedDouble, setfixedDouble] = useState([]);
  const [staredDouble, setstaredDouble] = useState([]);

  function receive_stars(i) {
    setstaredDouble([...i]);
    console.log("Receive stars:", [...i]);
  }


  const callfromcheckbox = (i, j) => {
    if (j) {
      setfixedDouble([...fixedDouble, i]);
    }
    else {
      let tmp = [...fixedDouble];
      let tmp2 = tmp.filter((e) => e !== i);
      setfixedDouble([...tmp2]);
    }
  }

  return (
    <>
      <form>
        <div class="columns ">
          <div class="column">
            <p class="is-size-6 has-text-centered"><strong>Verwende für 6er Mannschaften den folgenden Generator. Für 4er Mannschaften siehe bitte die Erklärung unten.</strong></p>
            <p class="is-size-3 has-text-centered">1. Wer spielt?</p>
            <p class="is-size-4 has-text-centered">Trage hier die Spielernamen ein</p>

          </div>
        </div>
        <div class="columns ">
          <div class="column is-4 is-offset-2">
            <div class="field">
              <label class="label">Position 1</label>
              <input class="input" type="text" value={player1} onChange={(e) => setplayer1(e.target.value)} />
            </div>

            <div class="field">
              <label class="label">Position 2</label>
              <input class="input" type="text" value={player2} onChange={(e) => setplayer2(e.target.value)} />
              <br></br>
            </div>

            <div class="field">
              <label class="label">Position 3</label>
              <input class="input" type="text" value={player3} onChange={(e) => setplayer3(e.target.value)} /> <br></br>
            </div>

          </div>
          <div class="column is-4">
            <div class="field">
              <label class="label">Position 4</label>
              <input class="input" type="text" value={player4} onChange={(e) => setplayer4(e.target.value)} /> <br></br>
            </div>

            <div class="field">
              <label class="label">Position 5</label>
              <input class="input" type="text" value={player5} onChange={(e) => setplayer5(e.target.value)} /> <br></br>
            </div>

            <div class="field">
              <label class="label">Position 6</label>
              <input class="input" type="text" value={player6} onChange={(e) => setplayer6(e.target.value)} /> <br></br>
            </div>
          </div>
        </div>
      </form>
      <div class="columns ">
        <div class="column is-8 is-offset-2">
          <article class="message is-info px-1">
            <div class="message-header">
              <p>Tipp zu Einwechslungen</p>
            </div>
            <div class="message-body">
              <p>Wenn ein oder mehr Spieler nach den Einzeln eingewechselt werden,
                ist weiterhin die Rangfolge der Meldeliste zu beachten.
              </p>

              <p>Daher bei Einwechslungen am besten zunächst alle Doppelspieler in der
                Meldeliste finden und dann oben der Reihe nach eintragen.
              </p>
            </div>
          </article>
        </div>
      </div>
      <div class="columns">
        <div class="column">

          <p class="is-size-3 has-text-centered">2. Doppel setzen (optional)</p>
          <p class="is-size-4 has-text-centered">Filtere die Liste nach gewünschten Paarungen</p>
        </div>

      </div>
      <div class="columns ">
        <div class="column is-offset-4">
          <table class="table">
            <tbody>
              <tr>
                <td><ComboCheckbox p1={player1} p2={player2} callback={callfromcheckbox} /></td>
                <td><ComboCheckbox p1={player1} p2={player3} callback={callfromcheckbox} /></td>
                <td><ComboCheckbox p1={player1} p2={player4} callback={callfromcheckbox} /></td>
                <td><ComboCheckbox p1={player1} p2={player5} callback={callfromcheckbox} /></td>
                <td><ComboCheckbox p1={player1} p2={player6} callback={callfromcheckbox} /></td>
              </tr>

              <tr>
                <td><ComboCheckbox p1={player2} p2={player3} callback={callfromcheckbox} /></td>
                <td><ComboCheckbox p1={player2} p2={player4} callback={callfromcheckbox} /></td>
                <td><ComboCheckbox p1={player2} p2={player5} callback={callfromcheckbox} /></td>
                <td><ComboCheckbox p1={player2} p2={player6} callback={callfromcheckbox} /></td>
                <td></td>
              </tr>

              <tr>
                <td><ComboCheckbox p1={player3} p2={player4} callback={callfromcheckbox} /></td>
                <td><ComboCheckbox p1={player3} p2={player5} callback={callfromcheckbox} /></td>
                <td><ComboCheckbox p1={player3} p2={player6} callback={callfromcheckbox} /></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><ComboCheckbox p1={player4} p2={player5} callback={callfromcheckbox} /></td>
                <td><ComboCheckbox p1={player4} p2={player6} callback={callfromcheckbox} /></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><ComboCheckbox p1={player5} p2={player6} callback={callfromcheckbox} /></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="columns is-centered">
        <div class="column">
          <p class="is-size-3 has-text-centered">Mögliche Kombinationen:</p>
        </div>
      </div>
      <div class="columns is-centered px-1">
        <ComboTable p1={player1} p2={player2} p3={player3} p4={player4} p5={player5} p6={player6} p_filter={fixedDouble} pass_stars={receive_stars} />
      </div>
      <div></div>

      <div class="columns is-centered">
        <div class="column">
          <p class="is-size-3 has-text-centered">Favoriten</p>
          <p class="has-text-centered">Zeige alle Doppel, die in der ⭐ Spalte markiert wurden.</p>
        </div>
      </div>

      <div class="columns is-centered px1">
        <table class="table">
          <th colSpan="1"><abbr title="Doppel 1">Doppel 1</abbr></th>
          <th colSpan="1"><abbr title="Doppel 2">Doppel 2</abbr></th>
          <th colSpan="1"><abbr title="Doppel 3">Doppel 3</abbr></th>
          <tbody>
            <StarRow stared={staredDouble} />
          </tbody>
        </table>
      </div>

    </>
  );
}


function StarRow(props) {
  const show = props.stared.map((z) => {
    const dp1 = z[0];
    const dp2 = z[1];
    const dp3 = z[2];
    const dp4 = z[3];
    const dp5 = z[4];
    const dp6 = z[5];

    return (
      <tr>
        <td>{dp1} + {dp2} </td>
        <td>{dp3} + {dp4}</td>
        <td>{dp5} + {dp6}</td>
      </tr>
    );
  }
  )
  return show;
}

function ComboCheckbox(props) {
  const [useCombo, setuseCombo] = useState(true);
  const id = props.p1 + props.p2
  function handler() {
    setuseCombo(!useCombo);
    props.callback(id, useCombo);
  }
  return (
    <form>
      <label class="checkbox">
        {props.p1} + {props.p2}
        <input type="checkbox" onChange={handler} />
      </label>
    </form>

  );
}

function StarCheckbox(props) {
  const [useStar, setuseStar] = useState(props.checked);

  const row = [props.p1, props.p2, props.p3, props.p4, props.p5, props.p6]
  function handler() {
    setuseStar(!useStar);
    props.callback(row, !useStar);
  }
  return (
    <input type="checkbox" checked={props.checked} onChange={handler} />
  );
}



function App() {
  return (
    <>
      <section class="has-background-white-bis px-1">
        <Player />
        <div class="columns">
          <div class="column is-10 is-offset-1">
            <section>
              <p class="is-size-3">Hintergrund</p>
              <p>Bei Mannschaftsspielen im Tennis (z.B. Medenspiele) spielen meist 4er oder 6er Mannschaften gegeneinander.
                Dabei werden zunächst die Einzel ausgetragen, indem die Spieler je nach ihrer gesetzten Rangfolge
                aufeinander treffen. Anschließend werden dann noch zwei bzw. drei Doppel ausgetragen.
                Dafür legen die Mannschaften jeweils geheim fest, wer in Doppel eins, zwei und drei spielt.
                Es können auch Spieler nach den Einzeln eingewechselt werden.
                Dabei ist zu beachten, dass die Summe der Positionen vom ersten Doppel bis zum dritten Doppel nicht kleiner werden darf. Z.B. ist dies eine zulässige Aufstellung
                <ul>
                  <li>  1. Doppel: 1+2 (= 3), 2. Doppel: 3+4 (= 7), 3. Doppel: 5+6 (= 11)</li>
                </ul>
                weil 3 &le; 7 &le; 11.
                Außerdem darf der Spieler an Position eins nicht im dritten Doppel spielen.
              </p>
              <p class="mt-2 mb-5"> Bei 4er Mannschaften sind die Möglichkeiten schnell diskutiert, weil es vier Optionen gibt.
                <ul>
                  <li>  1.Doppel: 1+2, 2.Doppel: 3+4 </li>
                  <li>  1.Doppel: 1+3, 2.Doppel: 2+4 </li>
                  <li>  1.Doppel: 1+4, 2.Doppel: 2+3 </li>
                  <li>  1.Doppel: 2+3, 2.Doppel: 1+4 </li>

                </ul>
                Jedoch wird der Fall bei 6er Mannschaften deutlich komplizierter. Dafür gibt des oben den Generator.
              </p>
            </section>
            <p class="is-size-3">Relevante Wettspielordnung</p>
            <p class="mb-3">
              Bitte beachte, dass die genauen Regeln für die Doppelaufstellung in der jeweils gültigen Wettspielordnung der für Dich relevanten Organisation gelten. Diese Seite orientiert sich an den Regeln des DTB E.V. und TVM e.V., die bezogen auf die erlaubten Doppelaufstellungen identisch sind.
            </p>
            <article class="message is-dark px-1">
              <div class="message-header">
                <p>Auszug aus der Wettspielordnung</p>
              </div>
              <div class="message-body">
                <p>"Die in den Doppeln einzusetzenden Spieler erhalten bei 6er Mannschaften die
                  Platzziffern 1 bis 6 bzw. bei 4er Mannschaften die Platzziffern 1 bis 4. Diese ergeben sich aus der Reihenfolge der Spieler in der Mannschaftsmeldung.
                  Bei der Aufstellung der Doppel darf die Summe der Platzziffern eines Doppelpaares
                  nicht größer sein als die des folgenden. Der Spieler mit der Platzziffer 1 ist im 3. Doppel nicht spielberechtigt." <a href='https://www.dtb-tennis.de/content/download/14531/154989/version/7/file/Wettspielordnung.pdf'> Wettspielordnung des
                    DTB E.V.</a></p>

                <p></p>
              </div>
            </article>
            <p class="is-size-3">Über diese Webseite</p>
            <p class="mb-3">
              Hi! Ich bin der Tennisspieler und Programmierer hinter dieser Seite.
              Die Idee für diesen Doppelgenerator ist mir während der Medenspiele in den Sinn gekommen.
              Wenn das letzte Einzel auf der Zielgeraden läuft, fragt jemand aus der Mannschaft: "Wie spielen wir Doppel?" Es gibt circa drei Vorschläge, die auf einem Bierdeckel notiert werden. Dann muss schnell eine Entscheidung getroffen werden und am Ende ist sich keiner sicher, ob es nicht auch noch eine bessere Option gegeben hätte...Jetzt gibt es diese Seite :).
            </p>
            <p class="mb-5"><strong> Wenn Dir diese Seite wirklich gut gefällt, dann kannst Du das Projekt gerne per PayPal (<Obfuscate
              email="doppel@realdon.net" />) unterstützen. Vielen Dank dafür und mögen die Netzroller immer auf der anderen Seite landen.</strong></p>
            <p class="is-size-3">Eingegebene Daten</p>
            <p class="mb-3">
              Diese Webseite läuft komplett im Browser. Eingetippte Spielernamen werden nicht an Server weitergeleitet.
            </p>
          </div>
        </div>
      </section>



      <div class="columns">
        <div class="column">
          <footer class="footer has-background-grey-lighter">
            <div class="content has-text-centered ">
              <p>
                Email: &nbsp;
                <Obfuscate
                  email="doppel@realdon.net" />
              </p>
              <p><a href="/imprint.html"> Impressum </a> -
                <a href="/datenschutz.html"> Datenschutzerklärung </a></p>
              <p>2022-2023. Einige Rechte vorbehalten. </p>
              <p>
                Alle Angaben ohne Gewähr. Eine Haftung für Irrtümer ist ausgeschlossen.
              </p>
            </div>
          </footer>
        </div>
      </div>

    </>
  );
}

export default App;
